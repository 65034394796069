module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-theme-particl-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"http://localhost:2368","contentApiKey":"3f9b4d499eaf56099ce755bd0d"},"production":{"apiUrl":"http://localhost:2368","contentApiKey":"3f9b4d499eaf56099ce755bd0d"}},"siteConfig":{"siteUrl":"https://particl.news","infiniteScroll":false,"gatsbyImages":true,"postsPerPage":24,"siteTitleMeta":"Particl News","siteDescriptionMeta":"News & Insights from the project and its decentralized blockchain privacy e-commerce marketplace.","shortTitle":"Particl News","siteIcon":"favicon.png","backgroundColor":"#ffffff","themeColor":"#141416","verbose":false,"severity":"warn"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../gatsby-theme-particl-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    }]
